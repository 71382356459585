/* App.css */

.App {
  text-align: center;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #444;
  height: 50px;
  position: relative;
}

.fileNameBox {
  background-color: #f0f0f0;
  padding: 5px 15px;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
  position: absolute; /* 절대 위치로 설정 */
  right: calc(50% - 500px); /* body의 오른쪽 끝에 맞추기 위해 계산된 값 */
}

.fileNameBox p {
  margin: 0;
  color: black;
  font-weight: bold;
}

.body {
  display: flex;
  justify-content: center;
  width: 1100px;
  margin: auto;
}

.left, .right {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left {
  padding-right: 80px;
  /*border-right: 2px solid #000; !* 왼쪽과 오른쪽 사이에 경계선을 추가 *!*/
  border-right: 2px solid transparent; /* 시각적 균형을 위해 투명한 경계선을 추가 */
}

.right {
  padding-right: 80px;
  border-left: 2px solid transparent; /* 시각적 균형을 위해 투명한 경계선을 추가 */
}

article {
  height: 300px;
}
