.beforeCongitive {
  margin-bottom: 30px;
}

.graphLabel {
  margin-left: 20%;
  display: flex;
  /*justify-content: space-between;*/
  margin-bottom: -8px;
}

.graphLabel p {
  padding: 3px 5px;
  border-radius: 15%;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.graphContainer {
  display: flex;
  height: 45px;
  padding-bottom: 10px;
}

.graphButton {
  width: 22%;
  font-size: 16px;
  font-weight: bold;
  /*text-align: right;*/
  margin-right: 7px;
  background-color: white;
  border: 1px solid #c8c8c8;
  cursor: pointer;
  border-radius: 15px;
}

.beforeCongitive .graphButton {
  box-shadow: 0px 0px 3px #9b9b9b
}

.activeGraph {
  background-color: rgba(225, 134, 131, 0.6);
}

.afterCongitive h2, .beforeCongitive h2 {
  margin-bottom: 5px;
}

h3 {
  margin-top: 5px;
  margin-bottom: 2px;
}
