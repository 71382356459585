.graphContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.graphBox {
  width: 100%;
  height: 100%; /* 그래프 박스의 전체 높이 */
  /*border: 1px solid gray; !* 박스의 테두리 *!*/
  position: relative;
  display: flex;
  align-items: center; /* 그래프를 세로 중앙으로 정렬 */
  background-color: #faedcd; /* 배경색 설정 */
  padding: 2px; /* 그래프 박스의 안쪽 여백 */
  box-sizing: border-box; /* 패딩을 포함한 박스 모델 */
  border: transparent;
}

.graph {
  height: 70%; /* 그래프의 높이 */
  background-color: rgb(158, 159, 159);
  /*border: 2px solid black; !* 그래프의 테두리 *!*/
  border: transparent;
  box-sizing: border-box; /* 테두리와 내용물 간의 상호작용을 유지 */
}

.doubleGraph {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box; /* 패딩과 테두리를 포함한 크기 계산 */
}

.divisionLine {
  position: absolute;
  top: 2px; /* 점선이 그래프 테두리 위로 나오도록 조정 */
  bottom: 2px; /* 아래쪽도 동일하게 조정 */
  width: 0;
  border-left: 2px dashed #5e5e5e; /* 점선의 스타일 */
  pointer-events: none; /* 점선이 그래프 뒤로 숨지 않도록 설정 */
}


.divisionBox {
  position: absolute;
  height: 100%;
}
